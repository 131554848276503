import React from 'react'
import { 
    Grid, 
    Header,
    Icon,
    Image,
    Label, 
    Segment, 
} from 'semantic-ui-react'

export default class ServiceProvider extends React.Component {
    render(){
        //console.log('serviceprovider.js - debug', this.props)
        if(this.props.franchise_office_image !== ''){
            return (
                <Segment id="show_me_info">
                        <Header as='h2'>Services provided by: {'Hommati Office ' + this.props.franchise_office_code}</Header>
                        <Segment basic>
                            <Grid stackable={true}>
                                <Grid.Column width={4}>
                                    <Image src={this.props.franchise_office_image} fluid={true} />
                                </Grid.Column>
                                <Grid.Column width={12}>
                                    {/* the whole contact card goes in here now.... two rows. */}
                                    <Grid>
                                        { /* the first row is the contact info.... */}
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Segment basic>
                                                    <Label attached='top'>
                                                        Contact Information:
                                                    </Label>
                                                    { /* this is just the contact info grid */ }
                                                    <Grid columns={4} divided stackable={true}>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Header as='h5'>
                                                                    <Icon name='address card outline' /> {this.props.franchise_contact_name}
                                                                    <Header.Subheader>
                                                                        {this.props.franchise_contact_name_title}
                                                                    </Header.Subheader>
                                                                </Header>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <Header as='h5'>
                                                                    <Icon name='phone square' />{this.props.franchise_office_phone}
                                                                    <Header.Subheader>
                                                                        Office Phone
                                                                    </Header.Subheader>
                                                                </Header>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <Header as='h5'>
                                                                    <Icon name='at' />
                                                                        <div style={{fontSize: '.75vw'}}>
                                                                            {this.props.franchise_office_email}
                                                                        </div>
                                                                    <Header.Subheader>
                                                                        Office Email
                                                                    </Header.Subheader>
                                                                </Header>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <Header as='h5'>
                                                                    <Icon name='home'/>
                                                                        <div style={{fontSize: '.75vw'}}>
                                                                            {this.props.franchise_landing_page}
                                                                        </div>
                                                                    <Header.Subheader>
                                                                        Office Page
                                                                    </Header.Subheader>
                                                                </Header>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                    {/* this ends the contact info grid */}
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Segment padded={false} attached='top'>
                                                    <Label attached='top'>
                                                        About Us:
                                                    </Label>
                                                    <div>
                                                        {this.props.franchise_description}
                                                    </div>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                        
                                    
                                </Grid.Column>
                            </Grid>
                        </Segment>
                    </Segment>
            )
        } else {
            // not populated?
            return null;
        }
    }
}