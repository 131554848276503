import React from 'react'
import { 
//    Button, 
//    List, 
    Icon, 
    Input,
    Segment, 
    Header, 
//    Label 
} from 'semantic-ui-react'

export default class ListingLinks extends React.Component {
    copyClick = (link, text) => {
        navigator.clipboard.writeText(link)
        this.props.notify(text);    
    };
    render() {
        return (
            <Segment>
                <Header id='show_me_links' as='h2'><Icon name='linkify' />Hommati Links - 2</Header>
                <Segment basic>
                    <Header as='h3'>
                        <Header.Content>
                            Unbranded Link for MLS
                        </Header.Content>
                        <Header.Subheader>
                            This is the link you would use for your MLS
                        </Header.Subheader>
                    </Header>
                    <Input
                            fluid={true}
                            icon='copy outline'
                            iconPosition='left'
                            label={{ pointing: 'left', content: 'Click to copy', onClick:() => this.copyClick(this.props.unbranded_link, 'The link for your MLS been copied to your clipboard!') }}
                            labelPosition='right'
                            value={this.props.unbranded_link}
                        />
                    <Header as='h3'>
                        <Header.Content>
                            Link for Web and Social Media
                        </Header.Content>
                        <Header.Subheader>
                            This is the link you would use for your organizations website.
                        </Header.Subheader>
                    </Header>
                    <Input
                            fluid={true}
                            icon='copy outline'
                            iconPosition='left'
                            label={{ pointing: 'left', content: 'Click to copy', onClick:() => this.copyClick(this.props.branded_link, 'The branded link has been copied to your clipboard!') }}
                            labelPosition='right'
                            value={this.props.branded_link}
                        />
                    
                </Segment>

            </Segment>
        )
    }
}
