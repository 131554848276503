import axios from 'axios';
import React, { Component} from 'react'
import { 
    Modal, 
    Button,
    Image,
    Header
} from 'semantic-ui-react'
import { Client } from 'paho-mqtt';

export default class ShowModal extends Component {    
    constructor(props) {
        super(props)
        this.state= {
            'axios_status': {
                'fetching': false,
                'fetch_error': false,
                'fetch_error_message': null
            },
            'loading': false,
            'photodelivery_assemble_success': false,
            'interval_count':0,
            'interval_payment_status_count':0,
        }
        try {
            this.client = new Client('messaging.hommati.cloud', 15676,'/ws','clientId-' + parseInt(Math.random() * 100, 10));

            this.onConnect = this.onConnect.bind(this);
            this.onConnectionLost = this.onConnectionLost.bind(this);
            this.onMessageArrived = this.onMessageArrived.bind(this);
            this.checkPhotodeliveyAssembleStatus = this.checkPhotodeliveyAssembleStatus.bind(this);
        } catch (error) {
            console.error('Error creating MQTT client:', error.message);
            this.state.error = error.message;  // Handle error (show in UI, log, etc.)
        }

    }
    submit_one_click_payment = async () => {
        console.log('submit_one_click_payment - state' + this.state)
        this.setState(prevState => ({
            axios_status: {
              ...prevState.axios_status,           // copy all other key-value pairs of axios_status object
            fetching: true
            }
        }))
        this.connect()
        this.setState({ loading: true });
        const response = await axios.get(this.props.billing_details.ajax_billing_endpoint)
        .catch(function(error){
            if (error.response){
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                this.setState(prevState => ({
                    axios_status: {
                        ...prevState.axios_status,           // copy all other key-value pairs of axios_status object
                        fetching: false,
                        fetch_error: true,
                        fetch_error_message: error.response.data
                    },
                    loading: false
                }))
            }
        })
        console.log('response was: ', response)

        if (response.data.status === "true" || response.data.status === true){
            console.log('billed successfully')
            this.interval_id = setInterval(this.checkPhotodeliveyAssembleStatus,1000);
        } else {
            console.log('ERROR!!! failed to charge for photodelivery!')
            console.log('ERROR!!! Message received when attempting one-click payment', response.data.message)
            this.setState({ loading: false });
        }

    }
    viewClick = (link) => {
        window.open(link, "_blank", "noreferrer");
    }
    one_click_payment = () => {
        if (this.props.billing_details.ajax_billing_endpoint !== false){
            return (
                <Button color='blue' fluid={true} loading={this.state.loading} onClick={() => this.submit_one_click_payment()}>
                    One Click Payment with card on file
                </Button>
            )
        }
    }
    manual_payment = () => {
        if (this.props.billing_details.hasOwnProperty('link_manual_payment') && this.props.billing_details.link_manual_payment !== false){
            // then the object exists, and a button should be displayed...
            return (
                <Button fluid={true} disabled={this.state.loading} onClick={() => this.viewClick(this.props.billing_details.link_manual_payment)}>
                    Manually pay with a card through your Hommati dashboard
                </Button>
            )
        } else {
            console.log('problem with manual payment link?', this.props.billing_details)
            return (
                <Button color='red' disabled={this.state.loading} fluid={true} onClick={() => this.viewClick(this.props.billing_details.link_update_profile)}>
                    There was an error processing available payment options for this delivery.  <br/>Please click the "Manage Payment Methods" button below, or click this button to update your agent profile.
                </Button>
            )
        }
    }
    manage_payment_methods = () => {
        console.log('in manage_payment_methods', this.props.billing_details.link_manage_payment_methods)
        if (this.props.billing_details.hasOwnProperty('link_manage_payment_methods')){
            return (
                <Button fluid={true} disabled={this.state.loading} onClick={() => this.viewClick(this.props.billing_details.link_manage_payment_methods)}>
                    Manage payment methods
                </Button>
            )
        }
    }
    payment_methods = () => {
        if (this.props.billing_details.status_is_paid_for === false && this.props.billing_details.status_is_deferred_billing === false){
            return (
                <div style={{ display: "flex",flexDirection:"column", gap:"0.3rem"}}>
                    {this.one_click_payment()}
                    {this.manual_payment()}
                    {this.manage_payment_methods()}
                </div>
            )
        }
    }

    onConnect() {
        console.log('Connected to the broker');
        // Subscribe to the desired topic
        this.client.subscribe('photodelivery');
    }
    connect() {
        try {
            this.client.onConnectionLost = this.onConnectionLost;
            this.client.onMessageArrived = this.onMessageArrived;
            if (!this.client.isConnected()) {
                this.client.connect({
                onSuccess: this.onConnect,
                onFailure: (error) => {
                    console.error("Connection failed:", error.errorMessage);
                    this.setState({ error: error.errorMessage });
                },
                userName: 'default_user_w4eB8fzxfTRXk2XYSm2',
                password: 'LJqV8A-mEq99mK4lqopJNJYzUz5IvpT2',
                useSSL: true,
                keepAliveInterval: 60,
                timeout: 5
                });
            } else {
                console.log("Client is already connected");
            }
        } catch (error) {
            console.error('Error creating MQTT client:', error.message);
            this.state.error = error.message;  // Handle error (show in UI, log, etc.)
        }
    }
    
    onConnectionLost(responseObject) {
        if (responseObject.errorCode !== 0) {
          console.log('Connection lost:', responseObject.errorMessage);
          if(!this.state.photodelivery_assemble_success){
            this.connect()
          }
        }
    }
    
    onMessageArrived(message) {
        console.log('Message arrived: ', message.payloadString);
        const newMessage = JSON.parse(message.payloadString);
        if(newMessage && newMessage.photodelivery_id == this.props.delivery_id){
            console.log('delivery_id true');
            this.setState({ photodelivery_assemble_success: true});
        }
    }
    checkPhotodeliveyAssembleStatus(){
        console.log('interval_count ',this.state.interval_count);
        if(this.state.photodelivery_assemble_success || this.state.interval_count > 60){
            clearInterval(this.interval_id);
            this.props.setRefetch(new Date().getTime());
            ///# Interval added due to KV store chaching issue
            this.interval_payment_status = setInterval(()=>{
                this.props.setRefetch(new Date().getTime());
                console.log('interval_payment_status_count',this.state.interval_payment_status_count);
                this.setState((prevState) => ({
                    interval_payment_status_count: prevState.interval_payment_status_count + 1,
                }));
            },5000);

            this.interval_status_is_paid_for = setInterval(()=>{
                console.log('interval_status_is_paid_for');
                if(this.props.billing_details.status_is_paid_for ||  this.props.billing_details.status_is_deferred_billing || this.state.interval_payment_status_count > 5 || this.state.interval_count > 60){
                    this.setState({ loading: false });
                    this.props.close_modal()
                    clearInterval(this.interval_payment_status);
                    clearInterval(this.interval_status_is_paid_for);
                }
            },500);
        }
        this.setState((prevState) => ({
            interval_count: prevState.interval_count + 1,
        }));
    }

    render(){
        return (
            <Modal
                closeIcon
                centered={true}
                open={this.props.open} 
                onClose={() => this.props.close_modal()}
                closeOnEscape={false}
                closeOnDimmerClick={false}
            >
            <Modal.Header>Paying for your photo delivery</Modal.Header>
            <Modal.Content image>
                <Image size='medium' src='https://static-assets.hommati.com/hommati-logo-svg-cropped.svg' wrapped />
                <Modal.Description>
                    <Header>There are several payment methods available to you:</Header>
                    <p>This order has not yet been paid for.  Once payment has been made, all download functionality will be enabled, and the watermarks will be removed.</p>
                    <p><em>IMPORTANT:</em>After paying, it may take up to a minute for watermarks to be removed.  Please hit the refresh button on your browser after paying to see changes.</p>
                    <p>To continue, choose one of the options below:</p>                
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {this.payment_methods()}
            </Modal.Actions>
            </Modal>
        )
    }
}