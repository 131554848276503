import React, { Component } from 'react'
import { 
    Icon,
    Menu
} from 'semantic-ui-react'

export default class NewTopMenu extends Component {
    constructor(props) {
        super(props);
        this.state={'MenuActiveItem': 'show_me_photos'};
        this.btnTapped = this
        .btnTapped
        .bind(this);
    }
    //state = {}

    stickyStyle = () => {
        return(
            {
                'position': 'sticky',
                zIndex: '1000'
            }
        )
    }

    btnTapped(e, { name }) {
        this.setState({ MenuActiveItem: name })
        document.getElementById(name).scrollIntoView()
    }
    
    handleItemClick = (e, { name }) => this.setState({ activeItem: "photos" })
    
    render() {
        const { activeItem } = this.state

        return (
            <Menu 
                attached='bottom' 
                fluid={true}
                stackable={true}
                style={this.stickyStyle()}
                widths={8}
            >
                <Menu.Item
                    name='scroll_to_top'
                    active={activeItem === 'scroll_to_top'}
                    onClick={this.btnTapped}
                >
                        <Icon name='home' />Top of Page
                </Menu.Item>
                <Menu.Item
                    name='show_me_photos'
                    active={activeItem === 'show_me_photos'}
                    onClick={this.btnTapped}
                >

                        <Icon name='camera' />
                        Photos ({this.props.total_photos})
                </Menu.Item>
                <Menu.Item
                    name='show_me_floorplans'
                    active={activeItem === 'show_me_floorplans'}
                    onClick={this.btnTapped}
                >
                        <Icon name='rss' />
                        Floor Plans ({this.props.total_floorplans})
                </Menu.Item>
                <Menu.Item
                    name='show_me_brochures'
                    active={activeItem === 'show_me_brochures'}
                    onClick={this.btnTapped}
                >
                        <Icon name='file pdf outline' />
                        Brochures ({this.props.total_brochures})
                </Menu.Item>
                <Menu.Item
                    name='show_me_videos'
                    active={activeItem === 'show_me_videos'}
                    onClick={this.btnTapped}
                >
                        <Icon name='file video outline' />
                        Videos ({this.props.total_videos})
                </Menu.Item>
                <Menu.Item
                    name='show_me_tours'
                    active={activeItem === 'show_me_tours'}
                    onClick={this.btnTapped}
                >
                        <Icon name='globe' />
                        3D Tours ({this.props.total_3dtours})
                </Menu.Item>
                <Menu.Item
                    name='show_me_links'
                    active={activeItem === 'show_me_links'}
                    onClick={this.btnTapped}
                >
                        <Icon name='linkify' />
                        Links
                </Menu.Item>
            </Menu>
        )
    }
}