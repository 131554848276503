import React from 'react'
import { 
    Card, 
    Divider,
    Segment, 
    Label, 
    Header, 
    Icon 
} from 'semantic-ui-react'
import FloorplanCard from './FloorplanCard';

export default class FloorPlans extends React.Component {
    //componentDidMount() {
    //}

    img_viewer = (target, filename) => {
        //this.props.img_viewer(target, filename);
        const this_viewer = new this.props.viewer(document.getElementById(target), {
            inline: false,
            container: 'modal',
            toolbar: false,
            viewed() {
                this_viewer.zoomTo(1);
            },
        });
    }

    click = (target, filename) => {
        this.props.download(target, filename);
    }

    arraymap = () => {
        //console.log('floorplans.js - arraymap called')
        //console.log('floorplans.js - arraymap - payment_helper', this.props.payment_helper)
        return {
            'paid_status': this.props.paid_status, 
            'viewer': this.props.viewer, 
            'download': this.props.download,
            'all_floor_plans': this.props.deliveryFloorPlans,
            'payment_helper' : this.props.payment_helper,
            'payment_details' : this.props.payment_details
        };
    }

    show_floor_plans_single = (plain_or_dimensions) => {
        //console.log('floorplans.js - show_floor_plans_single - trying to draw floorplans of type: ', plain_or_dimensions);
        if (plain_or_dimensions === 'plain'){
            this.props.deliveryFloorPlans.jpeg.single_floor.plain.map(function(floorplan_list, i){
                //console.log('floorplans.js - show_floor_plans_single - plain', floorplan_list, i);
                return(
                    <FloorplanCard key={i} floorplan_type="plain" single_or_multi='single_floor' thumb={floorplan_list} title={floorplan_list.split('/').pop().split('_')[0].replace('building-', 'Building ') +' Floor ' + (Number(floorplan_list.split('/').pop().split('_').pop().split('.')[0]) + 1)} paid_status={this.paid_status} viewer={this.viewer} download={this.download} all_floor_plans={this.all_floor_plans}  payment_helper={this.props.payment_helper} payment_details={this.props.payment_details} />
                )
            }, this.arraymap())
        } else {
            this.props.deliveryFloorPlans.jpeg.single_floor.dimensions.map(function(floorplan_dim_list, i){
                //console.log('floorplans.js - show_floor_plans_single - dimensions', floorplan_dim_list, i);
                return(
                    <FloorplanCard key={i} deliveryfloorplans={this.deliveryFloorPlans} floorplan_type="dimensions" single_or_multi='single_floor' thumb={floorplan_dim_list} title={floorplan_dim_list.split('/').pop().split('_')[0].replace('building-', 'Building ') +' Floor ' + (Number(floorplan_dim_list.split('/').pop().split('_').pop().split('.')[0]) + 1)} paid_status={this.paid_status} viewer={this.viewer} download={this.download} all_floor_plans={this.all_floor_plans}  payment_helper={this.props.payment_helper} payment_details={this.props.payment_details}/>
                )
            }, this.arraymap())
        }
        
    }

    render(props) {
        if (this.props.deliveryFloorPlans.jpeg.single_floor.plain.length === 0 && this.props.deliveryFloorPlans.jpeg.combined.dimensions.length === 0) {
            return (
                <Segment placeholder attached='bottom'>
                    <Header icon>
                        <Icon name='file outline' />
                        No floor plans were created for this job.
                    </Header>
                </Segment>
            )
        } else {
            return (

                    <Segment basic>
                        <Label as='h3' color='orange' ribbon pointing="below" size='huge'>
                            Images without dimensions
                        </Label>
                        <Card.Group>
                            { 
                                this.props.deliveryFloorPlans.jpeg.single_floor.plain.map(function(floorplan_list, i){
                                    return(
                                        <FloorplanCard key={i} floorplan_type="plain" single_or_multi='single_floor' thumb={floorplan_list} title={floorplan_list.split('/').pop().split('_')[0].replace('building-', 'Building ') +' Floor ' + (Number(floorplan_list.split('/').pop().split('_').pop().split('.')[0]) + 1)} paid_status={this.paid_status} viewer={this.viewer} download={this.download} all_floor_plans={this.all_floor_plans}   payment_helper={this.payment_helper} payment_details={this.payment_details}/>
                                    )
                                }, this.arraymap())
                            }
                            <FloorplanCard floorplan_type="plain"  single_or_multi='combined' thumb={this.props.deliveryFloorPlans.jpeg.combined.plain[0]} title='All floors combined' paid_status={this.props.paid_status} viewer={this.props.viewer} download={this.props.download} all_floor_plans={this.props.deliveryFloorPlans}   payment_helper={this.payment_helper} payment_details={this.payment_details}/>
                        </Card.Group>
                        <Divider />
                        <Label as='h3' color='orange' ribbon pointing="below" size='huge'>
                            Images with dimensions
                        </Label>
                        <Card.Group>
                        { 
                            this.props.deliveryFloorPlans.jpeg.single_floor.dimensions.map(function(floorplan_dim_list, i){
                                return(
                                    <FloorplanCard key={Math.random()} deliveryfloorplans={this.deliveryFloorPlans} floorplan_type="dimensions" single_or_multi='single_floor' thumb={floorplan_dim_list} title={floorplan_dim_list.split('/').pop().split('_')[0].replace('building-', 'Building ') +' Floor ' + (Number(floorplan_dim_list.split('/').pop().split('_').pop().split('.')[0]) + 1)} paid_status={this.paid_status} viewer={this.viewer} download={this.download} all_floor_plans={this.all_floor_plans}  payment_helper={this.payment_helper} payment_details={this.payment_details}/>
                                )
                            }, this.arraymap())
                        }
                        <FloorplanCard floorplan_type="dimensions"  single_or_multi='combined' thumb={this.props.deliveryFloorPlans.jpeg.combined.dimensions[0]} title='All floors combined' paid_status={this.props.paid_status} viewer={this.props.viewer} download={this.props.download} all_floor_plans={this.props.deliveryFloorPlans} payment_helper={this.payment_helper} payment_details={this.payment_details}/>
                        </Card.Group>
                    </Segment>
            )
        }
    }
}
