import React from 'react'
import { 
    Menu,
    Card,
    Icon
} from 'semantic-ui-react'

// depends on video-react from https://video-react.js.org
import "video-react/dist/video-react.css";
import { Player, ControlBar } from 'video-react';

export default class VideoCard extends React.Component {
    startDirectDownload = (fileURL) => {
        window.open(fileURL, "_blank", "noreferrer");
    }

    componentDidMount() {
        // subscribe state change
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    }

    handleStateChange(state, prevState) {
        // copy player state to this component's state
        this.setState({
            player: state,
            currentTime: state.currentTime
        });
    }

    click_download = (target, filename) => {
        this.props.notify('Your video download is starting... Please be patient - it may take a little bit to download on slower connections.');    
        this.startDirectDownload(target);
    }

    render_buttons = (paid_status) => {
        if (paid_status === true | paid_status === 'yes') {
            return (
                    <Menu icon={true} stackable={true} attached="top" color='orange'>
                        <Menu.Item
                            name='play'
                            onClick={() => this.player.play()}>
                            <Icon name='play' />
                            Play
                        </Menu.Item>
                        <Menu.Item
                            name='pause'
                            onClick={() => this.player.pause()}>
                            <Icon name='pause' />
                            Pause
                        </Menu.Item>
                        <Menu.Item
                            name='fullscreen'
                            onClick={() => this.player.toggleFullscreen()}>
                            <Icon name='expand' />
                            Fullscreen
                        </Menu.Item>
                        <Menu.Item
                            target="_blank"
                            position='right'
                            color='orange'
                            onClick={() => this.click_download(this.props.video_src, decodeURIComponent(this.props.video_src.split('/').pop().split('.')[0])  + '.' + this.props.video_src.split('.').pop())}>
                                <Icon name='download' />
                                Download
                        </Menu.Item>
                    </Menu>
                
            )
        } else {
            return (
                <div>
                    Not yet paid for.
                </div>
            )
        }
    };
    render(){
        return (
            <Card
                style={{
                    width: "40vw"
                }}
            >
                <Card.Content>
                    <Card.Header>{decodeURIComponent(this.props.video_src.split('/').pop().split('.')[0])}</Card.Header>
                </Card.Content>
                <Card.Content>
                    <Player
                        ref={player => {this.player = player;}}
                        playsInline
                        poster="https://static-assets.hommati.com/hommati-logo-svg-cropped-whitebg.svg"
                        src={this.props.video_src}
                    >
                        <ControlBar 
                            ref={controlbar => {this.controlbar = controlbar;}}
                            autoHide={false} 
                        />
                    </Player>
                    {this.render_buttons(this.props.paid_status)}
                </Card.Content>
            </Card>
        )
    }
}