import React from 'react'
import { 
//    Divider, 
    Card, 
//    Segment, 
    Image,
    //Label, 
//    List, 
//    Header, 
//    Icon 
} from 'semantic-ui-react'
import VirtualTourCard from './VirtualTourCard';


export default class VirtualTours extends React.Component {

    virtualToursMap = (tours_list, i) => {
        var actual_tour_link = tours_list['link'].replace('floorfy.com', 'hommati.tours')
        return (
            <VirtualTourCard key={'virtual_tour_' + String(i)} 
                tour_link={actual_tour_link} 
                building_number={tours_list.building} 
                paid_status={this.props.paid_status}
                notify={this.props.notify}
                payment_helper={this.props.payment_helper}
                payment_details={this.props.payment_details}
            />
        )
    }
    render(){
        if(this.props.virtual_tours.length === 0){
            // there are no 3d tours for this bitch....
            return (
                
                        <Image 
                            centered={true}
                            size='huge'
                            src='https://static-assets.hommati.com/delivery/assets/not_ordered-tour.webp' 
                        />
                
            )
        } else {
            // we got good data here...
            return (
                <Card.Group>
                        {
                            this.props.virtual_tours.map(this.virtualToursMap)
                        }
                </Card.Group>
            )
        }
    }
}