import React, { Component, useEffect, useState} from 'react'
import 'semantic-ui-css/semantic.min.css';

import { 
    Button,
    Card,
    Dropdown,
    //Divider,
    Header,
    Icon,
    Image,
    //Grid,
    //List,
    //Label,
    Menu,
    Popup,
    Segment
} from 'semantic-ui-react'
import toast, { Toaster } from 'react-hot-toast';
import ShowModal from './ShowModal';
import NewTopMenu from './NewTopMenu';
import PhotoCard from './PhotoCard';
import FloorPlans from './FloorPlans';
import Brochures from './Brochures';
import VirtualTours from './VirtualTours';
import ListingLinks from './ListingLinks';
import ServiceProvider from './ServiceProvider';
import VideoSection from './VideoSection';

export default class MainAppView extends Component {

    paymentHelper = (payment_details, source) => {
        console.log('paymenthelper invoked from ' + source, payment_details);
        this.setState(
            {
                photo_zip_dropdown: this.state.photo_zip_dropdown,
                show_payment_modal: true
            }
        )
    }
    click = (target, filename) => {
        this.notify('Your download will start in just a moment...')
        //this.props.download(target, filename);
        this.startDirectDownload(target);
    }

    // eslint-disable-next-line
    dropdownclick = (event, data) => {
        this.notify('Your download will start in just a moment...')
        this.startDirectDownload(data.value);
    }
    notify = (message) => toast.success(message);

    startDirectDownload = (fileURL) => {
        window.open(fileURL, "_blank", "noreferrer");
        this.props.updatePhotosDowloadStatus()
    }

    getWindowDimensions = () => {
        // eslint-disable-next-line
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width
        };
    }
    useWindowDimensions = () => {
        const [windowDimensions, setWindowDimensions] = useState(this.getWindowDimensions());
        useEffect(() => {
            // eslint-disable-next-line
            function handleResize() {
                setWindowDimensions(this.getWindowDimensions());
            }
            window.addEventListener('resize', this.handleResize);
            return () => window.removeEventListener('resize', this.handleResize);
        }, []);
      
        return windowDimensions;
      }

    add_zip_download_button = (paid_status, zip_array, zip_key, sub_key, file_list, filename, description) => {
        //console.log('mainappview.js: add_zip_download_button', paid_status, zip_array, zip_key, sub_key, file_list, filename, description)

        /*
            example call: 
                                        this.add_zip_download_button(
                                            this.props.billingDetails.status_is_paid_for,
                                            this.props.zip_files,
                                            'floor_plans',
                                            undefined,
                                            this.props.deliveryFloorPlans,
                                            this.props.deliveryAddress + '-floor_plans.zip',
                                            'Download All Floorplans (All Formats)'
                                        )

        */
        //console.log('mainappview.js: add_zip_download_button', paid_status, paid_status===true)
        if (paid_status === true || paid_status === 'yes') {
            if ((typeof zip_array[zip_key] !== 'undefined') && file_list.length > 0){
                if (sub_key === null || sub_key === undefined) {
                    console.log('mainappview.js: add_zip_download_button - no sub key')
                    return(
                        <Button color='blue' name={filename}
                            onClick={() => this.click(zip_array[zip_key], filename)}>
                            <Icon name='file archive' />{description}
                        </Button>
                    )
                } else {
                    if (zip_key === 'floor_plans'){
                        console.log('mainappview.js: add_zip_download_button - wrong conditional?', sub_key)
                    }
                    return(
                        <Button color='blue' name={filename}
                            onClick={() => this.click(zip_array[zip_key][sub_key], filename)}>
                            <Icon name='file archive' />{description}
                        </Button>
                    )
                }    
            } else {
                //if (zip_key === 'floor_plans'){
                //    console.log('no files defined? - zip_array', zip_array)
                //    console.log('no files defined? - zip_key', zip_key)
                //    console.log('no files defined? file_list', file_list, file_list.length)
                //    console.log('no files defined? - subarray', zip_array[zip_key])
                //}
                // not defined, or no files to download  return blank
                return
            }
        } else {
            // not paid for yet -- return here
            return (
                <Popup content='Click here to pay for this service, and enable downloads.' trigger={
                    <Button color='red' inverted onClick={() => this.paymentHelper(this.props.billingDetails, 'button in mainappview')}>Click here to pay for this service.</Button>
                } />
            )
        }
    }
    add_zip_download_option = (paid_status, zip_array, zip_key) => {
        var this_button_desc = ''
        switch(zip_key){
            case 'photos':
                this_button_desc = 'photos';
                break;
            case 'all':
                this_button_desc = 'files';
                break;
            default:
                this_button_desc = zip_key
                break;
        }
        //console.log('debug - add_zip_download_button', paid_status)
        if (paid_status === true || paid_status === 'yes') {
            if (zip_array[zip_key] !== undefined){
                    let new_state = []
                    for (const this_zip of Object.keys(zip_array[zip_key])){
                        if (zip_array[zip_key][this_zip].length > 0){
                            switch(this_zip){
                                case 'all':
                                    new_state.push(
                                        {
                                            key: this_zip,
                                            text: 'Click here to download zip bundle (all resolutions)',
                                            value: String(zip_array[zip_key][this_zip]),
                                            filename: this.props.deliveryAddress + ' - ' + this_button_desc + ' (all).zip',
                                            label: { color: 'orange', icon: 'file archive', content:'zip'},
                                            onClick: this.dropdownclick
                                        }

                                    )
                                    break;
                                    case 'hi_res':
                                        new_state.push(
                                            {
                                                key: this_zip,
                                                text: 'Click here to download zip bundle (high quality only)',
                                                value: String(zip_array[zip_key][this_zip]),
                                                filename: this.props.deliveryAddress + ' - ' + this_button_desc + ' (high res).zip',
                                                label: { color: 'orange', icon: 'file archive', content:'zip'},
                                                onClick: this.dropdownclick
                                            }
    
                                        )
                                        break;
                                        case 'low_res':
                                            new_state.push(
                                                {
                                                    key: this_zip,
                                                    text: 'Click here to download zip bundle (web optimized)',
                                                    value: String(zip_array[zip_key][this_zip]),
                                                    filename: this.props.deliveryAddress + ' - ' + this_button_desc + ' (web optimized).zip',
                                                    label: { color: 'orange', icon: 'file archive', content:'zip'},
                                                    onClick: this.dropdownclick
                                                }
        
                                            )
                                            break;
                                default:
                                    console.log('zip_array hit default case')
                            }
                        }
                    }
                    return new_state
                    
            } else {
                // not defined, or no files to download  return blank
                return [{
                    key: 'no_files',
                    text: 'No files to download',
                    value: 'no_files',
                    label: { color: 'red', icon: 'exclamation'},
                }]
            }
        } else {
            return [{
                key: 'click_to_pay',
                text: 'Click here to pay for this service, and enable downloads.',
                value: 'click_to_pay',
                label: { color: 'red', icon: 'exclamation', content: 'unpaid'},
                onClick: () => this.paymentHelper(this.props.billingDetails)
            }]
        }
    }

    update_download_options = (zip_files, is_paid_for) => {
        const newState = this.add_zip_download_option(
            is_paid_for,
            zip_files,
            'photos'
        )

        this.setState(
            {
                photo_zip_dropdown: newState,
                show_payment_modal: this.state.show_payment_modal
            }
        )

    }

    close_modal = () => {
        this.setState(
            {
                photo_zip_dropdown: this.state.photo_zip_dropdown,
                show_payment_modal: false
            }
        )
    }
    constructor(props) {
        super(props);
        //const [photo_zip_dropdown, set_photo_zip_dropdown] = useState(Array);
        // initialize values first..
        this.state= {
            'photo_zip_dropdown': [],
            'show_payment_modal': false,
        }
    }
    isValidUrl = (urlString)=> {
        var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
      '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    }

    deliveryPhotosMap = (this_photo, i) => {
        var this_photo_filename = String(decodeURI(this_photo)).split('/').pop();
        var ext_pos = this_photo_filename.lastIndexOf('.');
        var this_photo_base_name = this_photo_filename.substring(0, ext_pos);
        var this_photo_high_res = this.getHighResEquivalent(this_photo_base_name, this.props.deliveryPhotosFull)
        var paid_status = this.props.billingDetails.status_is_deferred_billing === true ? true : this.props.billingDetails.status_is_paid_for;
        if (this.isValidUrl(this_photo_high_res)){
            return (
                <PhotoCard key={String(i)+String(paid_status)} thumb={this_photo} title={this_photo_base_name} highquality={this_photo_high_res} lowquality={this_photo} download={this.props.download} viewer={this.props.viewer} paid_status={paid_status} payment_helper={this.paymentHelper} payment_details={this.props.billingDetails}/>
            )
        } else {
            // 2023-11-27 - this is a very strange fallthrough
            console.log('not a valid url for ', this_photo, this_photo_high_res);
            return (
                <PhotoCard key={String(i)+String(paid_status)} thumb={this_photo} title={this_photo_base_name} highquality={this_photo} lowquality={this_photo} download={this.props.download} viewer={this.props.viewer} paid_status={paid_status} payment_helper={this.paymentHelper} payment_details={this.props.billingDetails}/>
            )
        }
    }

    getHighResEquivalent = (needle, haystack) => {
        var haystack_pos = haystack.findIndex((x) => {
            var tmp_uri = x.split('/').pop();
            var ext_pos = tmp_uri.lastIndexOf('.');
            if (tmp_uri.substring(0, ext_pos) === needle){
                return x;
            } else {
                return false;
            }
        })
        if (haystack_pos !== false){
            return encodeURI(haystack[haystack_pos])
        } else {
            console.log('getHighResEquivalent - failed to find needle', needle, haystack)
            return needle
        }
    }

    getHighResCover = (needle, haystack) => {
        var needle_filename_base = decodeURI(needle).split('/').pop().split('.')[0]
        var haystack_pos = haystack.findIndex((x) => x.split('/').pop().split('.')[0] === needle_filename_base)
        //console.log('gethighrescover', needle_filename_base, haystack_pos)
        if (haystack_pos > 0){
            return encodeURI(haystack[haystack_pos])
        } else {
            return needle
        }
    }
    bannerStyle = () => {
        var this_width = this.getWindowDimensions()
        var cover_image_url = this.getHighResCover(this.props.property_info.cover_image, this.props.deliveryPhotosFull);
        //console.log('high res cover image:', cover_image_url)
        if (cover_image_url == null) {
            cover_image_url = 'https://www.hommati.com/images/properties/' + cover_image_url
        } else {
            if (cover_image_url.includes('https://')){
                // do nothing
            } else {
                cover_image_url = 'https://www.hommati.com/images/properties/' + cover_image_url
            }
        }
        
        var this_cover = 'url(' + cover_image_url + ')'
        return(
            {
                width: this_width,
                height: `400px`,
                backgroundImage: this_cover,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% 30%',
                position: 'relative'
            }
        )   
    }

    totalFloorPlans = () => {
        if (this.props.deliveryFloorPlans.jpeg.single_floor.dimensions.length === 0){
            return this.props.deliveryFloorPlans.jpeg.combined.plain.length;
        } else {
            return this.props.deliveryFloorPlans.jpeg.single_floor.dimensions.length;
        }
    }

    render(){
        //console.log('cover_image', this.props.cover_image)
        
        // eslint-disable-next-line
        const { windowHeight, windowWidth } = this.getWindowDimensions();
        return(
            <Segment id="scroll_to_top">
                    <ShowModal open={this.state.show_payment_modal} billing_details={this.props.billingDetails} delivery_id ={this.props.deliveryID} close_modal={this.close_modal} setRefetch={this.props.setRefetch}/>
                    <Toaster
                        toastOptions={{
                            duration: 4000,
                            success: {
                                style: {
                                    background: 'orange',
                                    zIndex: 9999
                                },
                            },
                            error: {
                                style: {
                                    background: 'red',
                                    zIndex: 9999
                                },
                            },
                        }} 
                    />
                    
                            <Menu 
                                borderless={true}
                                stackable={true}
                                >
                                <Menu.Item>
                                    <Image href='https://www.hommati.com' src='https://static-assets.hommati.com/hommati-logo-svg-cropped.svg' size='tiny' />
                                </Menu.Item>
                                <Menu.Menu position='right'>

                                <Dropdown 
                                        text='Download Zip Files (photos/floorplan/brochure)'
                                        labeled
                                        button={true}
                                        icon='dropdown'
                                        className='icon'
                                        options={
                                            this.add_zip_download_option(
                                                this.props.billingDetails.status_is_deferred_billing === true ? true : this.props.billingDetails.status_is_paid_for,
                                                this.props.zip_files,
                                                'all'
                                            )
                                        }
                                        style={{
                                            lineHeight: '2.5em'
                                        }}
                                    >
                                    </Dropdown>
                                
                                </Menu.Menu>
                                
                            </Menu>
                    
                    <Segment 
                        attached
                        basic 
                        textAlign='center' 
                        style={this.bannerStyle()}>
                        <div className='ServicesProvidedDiv'>
                            <div style={{fontWeight:'bold'}} className='ServicesProvidedNeon'>
                                {this.props.property_info.address}
                            </div>
                            <div style={{fontSize: '4vh'}} className='ServicesProvidedNeon'>
                                {this.props.property_info.citystatezip}
                            </div>                                
                        </div>

                    </Segment>
                        <NewTopMenu 
                        address=""
                        total_photos={this.props.deliveryPhotosWeb.length}
                        total_floorplans={this.totalFloorPlans()}
                        total_brochures='1'
                        total_videos={this.props.videos.length}
                        total_3dtours={this.props.tours.length}
                        />
                    <Segment>
                        <Header id='show_me_photos' as='h1' attached='top'>
                            <Icon 
                                name='camera retro' 
                                size='massive'
                                fitted={true}
                            />
                            <Header.Content>
                                Photos
                            </Header.Content>
                            <Header.Content>
                            <Dropdown 
                                        text='Download All Photos'
                                        labeled
                                        button={true}
                                        icon='dropdown'
                                        className='icon'
                                        options={
                                            this.add_zip_download_option(
                                                this.props.billingDetails.status_is_deferred_billing === true ? true : this.props.billingDetails.status_is_paid_for,
                                                this.props.zip_files,
                                                'photos'
                                            )
                                        }
                                        style={{
                                            lineHeight: '2.5em'
                                        }}
                                    >
                                    </Dropdown>
                            </Header.Content>
                        </Header>
                        <Card.Group centered={true}>
                        {
                            this.props.deliveryPhotosWeb.map(this.deliveryPhotosMap)
                        }
                        </Card.Group>
                    </Segment>
                    <Segment>
                        <Header id='show_me_floorplans' as='h1' attached='top'>
                            <Icon 
                                name='camera retro' 
                                size='massive'
                                fitted={true}
                            />
                            <Header.Content>
                                Floor Plans
                                <Header.Subheader>
                                    {
                                        this.add_zip_download_button(
                                            this.props.billingDetails.status_is_deferred_billing === true ? true : this.props.billingDetails.status_is_paid_for,
                                            this.props.zip_files,
                                            'floor_plans',
                                            null,
                                            this.props.deliveryFloorPlans.svg.combined.plain,
                                            this.props.property_info.address + '-floor_plans.zip',
                                            'Download All Floorplans (All Formats)'
                                        )
                                    }  
                                </Header.Subheader>
                                
                            </Header.Content>
                        </Header>
                        <Card.Group>
                            <FloorPlans deliveryFloorPlans={this.props.deliveryFloorPlans} paid_status={this.props.billingDetails.status_is_deferred_billing === true ? true : this.props.billingDetails.status_is_paid_for} payment_helper={this.paymentHelper} payment_details={this.props.billingDetails} viewer={this.props.viewer} download={this.props.download} />
                        </Card.Group>
                    </Segment>
                    <Segment>
                        <Header id='show_me_brochures' as='h1' attached='top'>
                            <Icon 
                                name='map' 
                                size='massive'
                                fitted={true}
                            />
                            <Header.Content>
                                Brochures
                                <Header.Subheader>
                                    {
                                        this.add_zip_download_button(
                                            this.props.billingDetails.status_is_deferred_billing === true ? true : this.props.billingDetails.status_is_paid_for,
                                            this.props.zip_files,
                                            'brochures',
                                            undefined,
                                            this.props.brochures,
                                            this.props.deliveryAddress + '-brochures.zip',
                                            'Download Brochure (All Formats)'
                                        )
                                    }  
                                </Header.Subheader>
                                
                            </Header.Content>
                        </Header>
                        <Card.Group>
                        <Brochures brochure_list={this.props.brochures} paid_status={this.props.billingDetails.status_is_deferred_billing === true ? true : this.props.billingDetails.status_is_paid_for} payment_helper={this.paymentHelper} payment_details={this.props.billingDetails} viewer={this.props.viewer} download={this.props.download} />
                        </Card.Group>
                    </Segment>
                    
                    <Segment>
                        <Header as='h2' id='show_me_tours'>
                            <Image size='small' src='https://static-assets.hommati.com/delivery/assets/icons/3D_tour.svg' />3D Tours</Header>

                            <VirtualTours 
                                virtual_tours={this.props.tours}
                                paid_status={this.props.billingDetails.status_is_deferred_billing === true ? true : this.props.billingDetails.status_is_paid_for}
                                payment_helper={this.paymentHelper} 
                                payment_details={this.props.billingDetails}
                                notify={this.notify}
                            />
                    </Segment>

                    <VideoSection 
                        video_url_list={this.props.videos}
                        paid_status={this.props.billingDetails.status_is_deferred_billing === true ? true : this.props.billingDetails.status_is_paid_for}
                        payment_helper={this.paymentHelper} 
                        payment_details={this.props.billingDetails}
                        download={this.props.download}
                        notify={this.notify}
                    />

                    <Segment id="show_me_links">
                        <ListingLinks 
                            branded_link={this.props.property_info.branded_link} 
                            unbranded_link={this.props.property_info.unbranded_link}
                            notify={this.notify}
                        />
                    </Segment>
                    <ServiceProvider 
                        franchise_office_code={this.props.franchise_info.code}
                        franchise_office_image={this.props.franchise_info.image}
                        franchise_contact_name={this.props.franchise_info.contact_name}
                        franchise_contact_name_title={this.props.franchise_info.contact_title}
                        franchise_office_phone={this.props.franchise_info.office_phone}
                        franchise_office_email={this.props.franchise_info.office_email}
                        franchise_landing_page={this.props.franchise_info.office_url}
                        franchise_description={this.props.franchise_info.description}

                    />
            </Segment>
        )
    }
}