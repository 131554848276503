import React from 'react'
import { Card, Image, Button, Popup } from 'semantic-ui-react'


export default class FloorplanCard extends React.Component {

    img_viewer = (target, filename) => {
        //this.props.img_viewer(target, filename);
        
        const this_viewer = new this.props.viewer(document.getElementById(target), {
            inline: false,
            container: 'modal',
            toolbar: false,
            viewed() {
                this_viewer.zoomTo(.3, {
                    x: window.innerWidth / 2,
                    y: this_viewer.innerHeight / 2,
                }
                );
            },
        });
    }
    image_changer = (paid_status) =>{
        if (paid_status === true || paid_status === 'yes') {
            return (
                <Popup content='Double click this image to view it in fullscreen' trigger={
                    <Image id={this.props.floorplan_type + this.props.single_or_multi + this.props.thumb.split('/').pop().split('.')[0]} size='medium' src={this.props.thumb} wrapped ui={false} onClick={() => this.img_viewer(this.props.floorplan_type + this.props.single_or_multi + this.props.thumb.split('/').pop().split('.')[0])} />
                } />
            )
        } else {
            return (
                <Popup content='Fullscreen viewing is disabled for floorplans until paid' trigger={
                    <Image id={this.props.thumb.split('/').pop().split('.')[0]} size='medium' src={this.props.thumb} wrapped ui={false} />
                } />
            )
        }
    }

    // TODO:  need to finish...
    check_for_buttons = (filename, file_format, drawing_type, combined) =>{
        if (typeof(this.props.all_floor_plans) === 'undefined'){
            console.log('check_for_buttons failed.')
            return
        }
        // drawing_type = plain | dimensions
        // combined = 'combined' | 'single-floor'
        
        // eslint-disable-next-line
        const this_eval = eval('this.props.all_floor_plans.' + file_format + '.' + combined + '.' + drawing_type);
        const map_eval = this_eval.map((x) => x.split('/').pop());
        let actual_ext = ''
        if (file_format === 'jpeg'){
            actual_ext = 'jpg'
        } else {
            actual_ext = file_format
        }
        const this_target_filename = filename.split('/').pop().split('.')[0] + '.' + actual_ext
        const this_target_loc = map_eval.indexOf(this_target_filename)
        if (this_target_loc !== -1){
            //key={'floorplanbutton' + file_format + combined + String(this_target_loc)}
            return (
                <Button
                    fluid={true} onClick={() => this.click(this_eval[this_target_loc], 'floor_plan-' + combined + '-' + drawing_type + '-' + this_target_filename)}>
                    Download {file_format.toUpperCase()}
                </Button>
            )
        } else {
            console.log('check_for_buttons - did not find it...', map_eval)
            console.log('check_for_buttons - was looking for', this_target_filename)
        }
        
    }

    button_changer = (paid_status) => {
        if (paid_status === true || paid_status === 'yes') {
            let these_buttons = []
            const all_file_types = ['jpeg', 'svg', 'pdf']
            //if (this.props.floorplan_type === 'plain'){
                // invoke button changer
                for (const file_format in all_file_types){
                    these_buttons.push(this.check_for_buttons(this.props.thumb, all_file_types[file_format], this.props.floorplan_type, this.props.single_or_multi, this.props.all_floor_plans))
                }
                return these_buttons.map(this_button => <div>{this_button}</div>)
            //} else if (this.props.floorplan_type === 'dimensions'){
                //for (const file_format in all_file_types){
                //    these_buttons.push(this.check_for_buttons(this.props.thumb, all_file_types[file_format], 'dimensions', 'single_floor', this.props.all_floor_plans))
                //}
                //return these_buttons.map(this_button => <div>{this_button}</div>)
            //} else {
            //    console.log('floorplan type?', this.props.floorplan_type);
            //}
        } else {
            return (
                <div className='ui one button'>
                    <Button color='red' onClick={() => this.props.payment_helper(this.props.payment_details)}>Click here to pay and remove watermarks</Button>
                </div>
            )
        }
    }

    click = (target, filename) => {
        this.props.download(target, filename);
    }
    render() {
        //console.log('floorplancard.js - render called', this.props.floorplan_type, this.props.single_or_multi)
        return (
            <Card>
                {this.image_changer(this.props.paid_status)}
                <Card.Content>
                    <Card.Header>{this.props.title}</Card.Header>
                </Card.Content>
                <Card.Content extra>
                    {this.button_changer(this.props.paid_status)}
                </Card.Content>
            </Card>
        )
    }
}
