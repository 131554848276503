import React from 'react'
import { 
    Card, 
    Image, 
    Button, 
    Divider,
    Input,
    Label
} from 'semantic-ui-react'
export default class TourCard extends React.Component {
    button_changer = (paid_status) => {
        if (paid_status === true || paid_status === 'yes') {
            return (
                <div className='ui'>
                    <Button 
                        color='blue' 
                        fluid={true}
                        onClick={() => this.viewClick(this.props.tour_link)}
                    >
                        View Tour
                    </Button>
                    <Divider />
                        <Label color='blue' ribbon>Unbranded link for MLS</Label>
                        <Input
                            fluid={true}
                            icon='copy outline'
                            iconPosition='left'
                            label={{ pointing: 'left', content: 'Click to copy', onClick:() => this.copyClick(this.props.tour_link, true, 'The 3D tour link for your MLS has been copied to your clipboard!') }}
                            labelPosition='right'
                            value={this.getTourLink(true)}
                        />
                        <Label color='blue' ribbon>Link for Web and Social Media</Label>
                        <Input
                            fluid={true}
                            icon='copy outline'
                            iconPosition='left'
                            label={{ pointing: 'left', content: 'Click to copy', onClick:() => this.copyClick(this.props.tour_link, false, 'The 3D tour link for web and social media has been copied to your clipboard!') }}
                            labelPosition='right'
                            placeholder='Link for web and social media'
                            value={this.getTourLink(false)}
                        />
                </div>
            )
        } else {
            return (
                <div className='ui one button'>
                    <Button color='red'onClick={() => this.props.payment_helper(this.props.payment_details)}>Click here to pay and view tour</Button>
                </div>
            )
        }
    }
    getTourLink = (mls_link) => {
        if (mls_link === true){
            return this.props.tour_link + '&mls=yes';
        } else {
            return this.props.tour_link
        }
        
    }
    copyClick = (link, mls_link, text) => {
        //console.log('virtualtourcard - copyclick', link, mls_link);
        if (mls_link === true){
            //console.log('totally an mls link...')
            link = link + '&mls=yes'
        }
        navigator.clipboard.writeText(link)
        this.props.notify(text);    
    };

    viewClick = (link) => {
        //console.log('virtualtourcard - click: link', link)    
        window.open(link, "_blank", "noreferrer");
    }
    render() {
        return (
            <Card>
                <Card.Content>
                    <Card.Header>3D Tour for building {this.props.building_number}</Card.Header>
                    <Image 
                        size='medium' 
                        src="https://s3.us-central-1.wasabisys.com/static-assets.hommati.com/logos/theta_logo.svg" 
                        wrapped ui={true} 
                        onClick={() => this.viewClick(this.props.tour_link)}
                    />
                </Card.Content>
                <Card.Content extra>
                    {this.button_changer(this.props.paid_status)}
                </Card.Content>
            </Card>
        )
    }
}
