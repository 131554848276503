import React from 'react'
import { 
    Card,
    Image,
    Segment, 
    Header, 
    Popup,
    Icon, 
    Button} from 'semantic-ui-react'

export default class Brochures extends React.Component {

    constructor(props) {
        super(props);
        //console.log('brochure list?', this.props.brochure_list)
    }
    img_viewer = (target, filename) => {
        //this.props.img_viewer(target, filename);
        const this_viewer = new this.props.viewer(document.getElementById(target), {
            inline: false,
            container: 'modal',
            toolbar: false,
            viewed() {
                this_viewer.zoomTo(1);
            },
        });
    }

    click = (target, filename) => {
        this.props.download(target, filename);
    }

    arraymap = () => {
        return {
            'paid_status': this.props.paid_status, 
            'viewer': this.props.viewer, 
            'download': this.props.download,
            'all_brochures': this.props.brochure_list
        };
    }

    render(props) {
        if (this.props.brochure_list.length === 0) {
            return (
                <Segment placeholder attached='bottom'>
                    <Header icon>
                        <Icon name='file outline' />
                        No brochures were created for this job.
                    </Header>
                </Segment>
            )
        } else {
            return (
                <Segment basic>
                    <Card>
                        
                            <Popup 
                                content='Double click this image to view it in fullscreen' 
                                trigger={
                                    <Image 
                                        id="cool_brochure_bro" 
                                        size='medium' 
                                        src={this.props.brochure_list.jpg[0]} 
                                        wrapped 
                                        ui={false} 
                                        onClick={
                                            () => this.img_viewer('cool_brochure_bro', this.props.brochure_list.jpg[0])
                                        } 
                                    />
                                } 
                            />
                        <Card.Content extra>
                            <Button
                                fluid={true}>
                                    Download PDF
                            </Button>
                        </Card.Content>
                    </Card>
                </Segment>
            )
        }
    }
}
