import React from 'react'
import { 
    Card, 
    Header,
    Icon,
    Image,
    Segment 
} from 'semantic-ui-react'
import VideoCard from './VideoCard'

// relies on videoreact from https://video-react.js.org
export default class VideoSection extends React.Component {
    arraymap = () => {
        //console.log('videosection.js - arraymap called', this.props)
        return {
            'paid_status': this.props.paid_status, 
            'download': this.props.download,
            'notify': this.props.notify,
            'payment_helper' : this.props.payment_helper,
            'payment_details' : this.props.payment_details
        };
    }

    render() {
        //console.log('videosection.js - paid status?', this.props.paid_status);
        if (this.props.video_url_list.length > 0){
            return (
                <Segment id='show_me_videos'>
                    <Header as='h2'>
                        <Icon name='file video outline' />Video
                    </Header>
                    <Card.Group
                        centered={true}
                        cards={2}
                    >
                    {
                        this.props.video_url_list.map(function(video_url, i){
                            console.log('videosection.js - rendering a video player', video_url, i);
                            return(
                                <VideoCard key={'video_' + String(i)} video_src={video_url} download={this.download} paid_status={this.paid_status} notify={this.notify} />
                            )
                        }, this.arraymap())
                    }
                    </Card.Group>
                </Segment>
            )
        } else {
            return (
                <Segment id='show_me_videos'>
                        <Header as='h2'>
                            <Icon name='file video outline' />Video
                        </Header>

                        <Image 
                            size='huge'
                            centered = {true}
                            src='https://static-assets.hommati.com/delivery/assets/not_ordered-video.webp' 
                        />
                </Segment>
            )
        }
        
    }
}
