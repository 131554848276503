import React from 'react'
import * as ReactDOM from "react-dom";
import 'semantic-ui-css/semantic.min.css';
import DeliveryApp from './DeliveryApp.js';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://ec6088be260ea953217ede04c8436011@o1168428.ingest.sentry.io/4506234522107904",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/delivery\.hommati\.com/],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: .1, // Capture 10% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(<DeliveryApp />, document.getElementById("root"));
