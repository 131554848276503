import React from 'react'
import { Card, Image, Button, Popup } from 'semantic-ui-react'


export default class PhotoCard extends React.Component {

//    constructor(props) {
//        super(props);
//    }
    img_viewer = (target, filename) => {
        //this.props.img_viewer(target, filename);
        const this_viewer = new this.props.viewer(document.getElementById(target), {
            inline: false,
            container: 'modal',
            toolbar: false,
            viewed() {
                this_viewer.zoomTo(1);
            },
        });
    }
    image_changer = (paid_status) =>{
            
        if (paid_status === true | paid_status === 'yes') {
            var this_photo_filename = String(decodeURI(this.props.thumb)).split('/').pop();
            var ext_pos = this_photo_filename.lastIndexOf('.');
            var this_photo_base_name = this_photo_filename.substring(0, ext_pos);    
            return (
                <Popup content='Double click this image to view it in fullscreen' trigger={
                    <Image id={this_photo_base_name} size='medium' src={this.props.thumb} wrapped ui={false} onClick={() => this.img_viewer(this_photo_base_name)} />
                } />
            )
        } else {
            var this_photo_filename = String(decodeURI(this.props.thumb)).split('/').pop();
            var ext_pos = this_photo_filename.lastIndexOf('.');
            var this_photo_base_name = this_photo_filename.substring(0, ext_pos);
            var this_image_watermarked = this.props.thumb.replace("/full/", "/watermark/");
            return (
                <Popup content='Double click this image to view it in fullscreen' trigger={
                    <Image id={this_photo_base_name} size='medium' src={this_image_watermarked} wrapped ui={false} onClick={() => this.img_viewer(this_photo_base_name)} />
                } />
            )
        }
    }

    button_changer = (paid_status, payment_helper, payment_details) => {
        if (paid_status === true || paid_status === 'yes') {
            return (
                <div className='ui two buttons'>
                <Button color='blue' onClick={() => this.click(this.props.highquality, 'print_quality-' + this.props.highquality.split('/').pop())}>Download High Quality Image</Button>
                </div>    
            )
        } else {
            return (
                <div className='ui one button'>
                    <Button color='red' onClick={() => payment_helper(payment_details)}>Click here to pay and remove watermarks</Button>
                </div>
            )
        }
    }

    click = (target, filename) => {
        var target_uridecoded = decodeURI(target);
        var filename_uridecoded = decodeURI(filename);
        console.log('attempting to click download for ', target, filename)
        this.props.download(target_uridecoded, filename_uridecoded);
    }
    render() {
        return (
            <Card>
                {this.image_changer(this.props.paid_status)}
                <Card.Content>
                    <Card.Header>{this.props.title.replace(/_/g, ' ')}</Card.Header>
                </Card.Content>
                <Card.Content extra>
                    {this.button_changer(this.props.paid_status, this.props.payment_helper, this.props.payment_details)}
                </Card.Content>
            </Card>
        )
    }
}
